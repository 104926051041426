import React from 'react'
import "./footer.css"
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const navigate = useNavigate();

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
      };
    return (
        <div>
            <div className='row main_div1  '>
                <div class="footer_head_section">
                    <div class="footer_head_name">Charmo Foods</div>
                    <div className='footer_social_main'>
                        <div class="footer_social_logo"><FaFacebook /> </div>
                        <div class="footer_social_logo"><FaInstagram /></div>
                        <div class=" footer_social_logo"><a href="https://www.linkedin.com/company/charmo-foods/" target="_blank" class="anchor_icon"><FaLinkedin /></a></div>
                    </div>
                </div>

                <div className='row main_div2'>
                    <div className='col-lg-3 col-md-3 col-sm-3 '>
                        <div className='header_main1' onClick={()=> navigate('/our-brands/ourBrands')} >
                            Our Brands
                        </div>
                        {/* <div className='content_main1'  onClick={()=> navigate('/our-brands/amigos_amigas')} > 
                            Amigos N Amigas
                        </div> */}
                        <div className='content_main1' onClick={()=> navigate('/our-brands/thali_seven')} >
                            Thali Seven
                        </div>
                        {/* <div className='content_main1' onClick={()=>scrollToSection('amigos_amigas')}>
                            Sardar Khan ki haveli
                        </div> */}
                        <div className='content_main1' onClick={()=> navigate('/our-brands/wokful_panda')}>
                            Wokful Panda
                        </div>
                        <div className='content_main1' onClick={()=> navigate('/our-brands/bowl_fusion')}>
                            Bowl Fusion
                        </div>
                        <div className='content_main1' onClick={()=> navigate('/our-brands/zauq_biryani')}>
                            Zauq Biryani
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 '>
                        <div className='header_main1' onClick={()=> navigate('/careers')}>
                            Careers
                        </div>
                        <div className='content_main1' onClick={()=> navigate('/careers')}>
                            Join Our Team
                        </div>
                        <div className='content_main1' onClick={()=> navigate('/careers')}>
                            Job Openings
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 '>
                        <div className='header_main1'>
                            Blog
                        </div>
                        <div className='content_main1'>
                            Blog Link
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 '>
                        <div className='header_main1' onClick={()=> navigate('/contact-us')}>
                            Contact Us
                        </div>
                        <div className='content_main1'>
                            support@charmofoods.com
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
