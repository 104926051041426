import { useRef, useEffect } from "react";

export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    
    const el = elRef.current;
    if (el) {
      const onWheel = e => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        if(e.deltaY > 0){
            let count = el.scrollLeft/window.innerWidth;
            el.scrollTo({
              left: (parseInt((el.scrollLeft + window.innerWidth)/window.innerWidth) )*window.innerWidth,
              behavior: "smooth"
            });
        }else{
            el.scrollTo({
                left: (parseInt((el.scrollLeft + 100 - window.innerWidth)/window.innerWidth))*window.innerWidth,
                behavior: "smooth"
              });
        }
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}


