import React from 'react'
import "./header.css"
import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="navbar-brand brandname" to="/">
            <img src={require("../../../assets/charmoFoodslogo.png")} height={"50px"} style={{cursor: 'pointer'}}/>
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse rightnavbartext" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
              <li className="nav-item">
                <Link className="nav-link active navbar_links" aria-current="page" to="/our-brands">Our brands</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active navbar_links" aria-current="page" to="/careers">Careers</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active navbar_links" aria-current="page" to="/contact-us">Contact Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active navbar_links" aria-current="page" to="#">Blogs</Link>
              </li>

            </ul>

          </div>
      </nav>
    </div>
  )
}
