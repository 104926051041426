import React, { useState } from 'react'
import "./contactus.css";
import contactus from '../../assets/contactus.jpg';
import Header from '../mainPage/header/header';
import Footer from '../mainPage/footer/footer';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

export default function Contact() {
    const [first_name, setfirst_name] = useState("")
    const [Last_name, setLast_name] = useState("")
    const [email_id, setemail_id] = useState("")
    const [phone, setphone] = useState("")
    const [desc, setdesc] = useState("")
    return (
        <div className=''>
            <Header />
            <div className='row contact_main_div contact_dark_div1'>
                <div className='col-lg-5 col-md-12 col-sm-12 contact_left_content1'>
                    <div className='contact_header_main2'>
                        Reach Out
                    </div>
                    <div className='contact_content_main1'>
                        info@charmofoods.com
                    </div>
                    <div className='contact_social_main'>
                        <div class="contact_social_logo"><FaFacebook /> </div>
                        <div class="contact_social_logo"><FaInstagram /></div>
                        <div class=" contact_social_logo"><FaLinkedin /></div>
                    </div>

                    <div className='contact_job_click_details'>
                        <form>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 contact-form-field'>
                                    <label>First Name</label>
                                    <input className='form-control' type='text' value={first_name} placeholder='First Name' onChange={(e) => { setfirst_name(e.target.value) }}></input>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 contact-form-field'>
                                    <label>Last Name</label>
                                    <input className='form-control' type='text' value={Last_name} placeholder='Last Name' onChange={(e) => { setLast_name(e.target.value) }}></input>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 contact-form-field'>
                                    <label>Email ID</label>
                                    <input className='form-control' type='email' value={email_id} placeholder='Email ID' onChange={(e) => { setemail_id(e.target.value) }}></input>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 contact-form-field'>
                                    <label>Phone Number</label>
                                    <input className='form-control' type='number' value={phone} placeholder='Phone Number' onChange={(e) => { setphone(e.target.value) }}></input>
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 contact-form-field'>
                                    <label>Comments</label>
                                    <textarea className='form-control' type='text' value={desc} placeholder='Comments' onChange={(e) => { setdesc(e.target.value) }}></textarea>
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 contact-form-field '>
                                    <button type='submit' className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='col-lg-7 col-md-6 col-sm-12 contact_image_main1'>
                    <img src={contactus} class="img-fluid rounded-start" alt="..." loading='lazy' />
                </div>
            </div>
            <Footer />


        </div>
    )
}
