import React from 'react'
import "./brands.css"
import Header from '../mainPage/header/header'
import { FaArrowRight } from "react-icons/fa";


export default function Brands(props) {
    return (
        < >
        

       
            {props.isFirst == true ? <div className='brand_head' id={props.id} style={{ backgroundImage: `url(${props.background})` }}>
                <div className='brand_main'>
                    <div className='title_main'>
                        {props.title}
                    </div>
                    <div className='desc_main'>
                        {props.desc}
                    </div>
                    <div className='scroll_main'>
                        Scroll Right {" "} <FaArrowRight />
                    </div>

                </div>
            </div>
                : <div className='brand_head_page' id={props.id} style={{ backgroundImage: `url(${props.background})` }}>
                    <div className='brand_main row'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='title_main'>
                                {props.title}
                            </div>
                            <div className='desc_main'>
                                {props.desc}
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 brand_logo_main'>
                            <div className='brand_logo'>
                                <img src={props.image} className='logoClass'></img>
                            </div>
                        </div>
                    </div>
                </div>}
        </>

    )
}
