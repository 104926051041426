import React from 'react'
import "./mainpage.css"
import Header from './header/header'
import Content from './content/content'
import Footer from './footer/footer'

export default function Mainpage() {
  return (
    <div className='mainpage_main'>
        <Header className= "Header_main"/>
        <Content className = "content_main_page"/>
        <Footer/>
    </div>
  )
}
