import React from 'react'
import "./career.css";
import contentimage from '../../assets/career.jpg';
import Header from '../mainPage/header/header';
import Footer from '../mainPage/footer/footer';

export default function Career() {
    return (
        <div className=''>
            <Header />
            <div className='row career_main_div career_dark_div1'>
                <div className='col-lg-6 col-md-6 col-sm-12 career_left_content1'>
                    <div className='career_header_main2'>
                    Join Us : Be a charmo
                    </div>
                    <div className='career_content_main1'>
                    Join Charmo Foods, the dynamic virtual kitchen startup that is reshaping the future of dining. We combine culinary passion with cutting-edge technology to create unforgettable gastronomic experiences delivered right to your doorstep. Be part of our talented team as we redefine convenience, innovation, and taste in the world of virtual kitchens.
                    </div>

                    <button className='career_job_click_details'>
                    Click to see all the Job Openings
                    </button>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 career_image_main1'>
                    <img src={contentimage} class="img-fluid rounded-start" alt="..." loading='lazy' />
                </div>
            </div>
            <Footer/>


        </div>
    )
}
