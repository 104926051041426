import React, { useEffect, useRef, useState }from 'react'
import "./brands.css"
import { useParams } from 'react-router-dom';
import Header from '../mainPage/header/header'
import { FaArrowRight } from "react-icons/fa";
import Brands from './brands';
import Scrollcontainer, { useHorizontalScroll } from './scrollcontainer';
import background1 from "../../assets/background1.png";
import background_thali from "../../assets/background_thali.jpg";
import background_bowl from "../../assets/background_bowl.jpg";
import background_amigos from "../../assets/background_amigos.jpg";
import background_wokfulpanda from "../../assets/background_wokfulpanda.jpg";
import background_zauq from "../../assets/background_zauq.jpg";


export default function OurBrands() {
    const {id} = useParams();
    const [index, setIndex] = useState(0);
    const scrollRef = useHorizontalScroll(index);
  
    useEffect(()=>{
        if(id != undefined){
            if(id == "thali_seven"){
                scrollRef.current.scrollTo({
                    left: 1*window.innerWidth,
                    behavior: "smooth"
                  });
            }else if(id=="wokful_panda"){
                scrollRef.current.scrollTo({
                    left: 4*window.innerWidth,
                    behavior: "smooth"
                  });
            }else if(id=="amigos_amigas"){
                scrollRef.current.scrollTo({
                    left: 5*window.innerWidth,
                    behavior: "smooth"
                  });
            }else if(id=="zauq_biryani"){
                scrollRef.current.scrollTo({
                    left: 2*window.innerWidth,
                    behavior: "smooth"
                  });
            }else if(id=="bowl_fusion"){
                scrollRef.current.scrollTo({
                    left: 3*window.innerWidth,
                    behavior: "smooth"
                  });
            }
        }
    },[])
    

    // const containerRef = useRef();
    
      
    
        // scrollToSection(id);
    return (
        
        <div className='main_brand_div' >
            <Header/>
            <div className='ourbrand_head d-flex' ref={scrollRef}>
                <div className='box'>
                    <Brands className="brandCards" id="ourBrands" title = "Explore Our Brands" desc = "Discover a new era of dining with Our Brands, the revolutionary cloud restaurant platform. Experience the convenience of our virtual restaurants, serving an expansive range of cuisines, from cherished classics to inventive fusions. Unleash your taste buds and savor extraordinary flavors, all from the cozy comfort of home." isFirst = {true} background = {background1}></Brands>
                </div>
                <div className='box'>
                    <Brands className="brandCards" id="thali_seven" title = "Thali Seven" image = {require('../../assets/thaliSeven.png')} desc = "Step into the world of Thali Seven, where culinary heritage meets modern convenience. Explore our virtual restaurant and indulge in the art of Indian thali dining. Experience the perfect harmony of flavors, textures, and aromas, all curated to transport you to the heart of India's diverse culinary traditions." isFirst = {false} background = {background_thali}></Brands>
                </div>
                {/* <div className='box'>
                    <Brands className="brandCards" title = "Sardar Khan Ki Haveli" desc = "Step into the world of Thali Seven, where culinary heritage meets modern convenience. Explore our virtual restaurant and indulge in the art of Indian thali dining. Experience the perfect harmony of flavors, textures, and aromas, all curated to transport you to the heart of India's diverse culinary traditions." isFirst = {false} background = {background1}></Brands>
                </div> */}
                {/* <div className='box'>
                    <Brands className="brandCards" id="amigos_amigas" title = "Amigos N Amigas" desc = "SBienvenidos to Amigos and Amigas, your virtual gateway to Mexican culinary bliss! Immerse yourself in a world of rich flavors, traditional recipes, and a fiesta of spices—all delivered right to your doorstep. Let's spice up your virtual dining experience!." isFirst = {false} background = {background_amigos}></Brands>
                </div> */}
                <div className='box'>
                    <Brands className="brandCards" id="zauq_biryani" title = "Zauq Biryani" image = {require('../../assets/zauqBiryani.png')} desc = "Welcome to Zauq Biryani, where every grain tells a flavorful tale. Immerse yourself in the aromatic world of biryani, where our expert chefs artfully blend spices and ingredients to create a symphony of taste that will leave you craving for more.." isFirst = {false} background = {background_zauq}></Brands>
                </div>
                <div className='box'>
                    <Brands className="brandCards" id="bowl_fusion" title = "Bowl Fusion" image = {require('../../assets/bowlFusion.png')} desc = "Welcome to Bowl Fusion, where flavors from around the world collide in a harmonious blend. Savor our diverse range of bowl creations, expertly crafted with fresh ingredients, bold spices, and a touch of culinary creativity. Get ready for a fusion feast like no other.." isFirst = {false} background = {background_bowl}></Brands>
                </div>
                <div className='box'>
                    <Brands className="brandCards" id="wokful_panda" title = "WokFul Panda" image = {require('../../assets/wokfulPanda.png')} desc = "Experience the magic of Chinese cuisine at Wokful Panda. Indulge in our authentic flavors, expertly crafted dishes, and warm hospitality. Discover a world of culinary delight where tradition meets innovation, all under the playful charm of our panda mascot." isFirst = {false} background = {background_wokfulpanda}></Brands>
                </div>
            </div>
            
        </div>
    )
}
