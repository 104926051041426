import React from 'react'
import contentimage from '../../../assets/life-style.jpg'
import contentimage2 from '../../../assets/content-2.jpg'
import contentimage3 from '../../../assets/content-3.jpg'
import contentimage4 from '../../../assets/content-4.jpg'
import "./content.css"

export default function Content() {
    return (
        <div className='main_content_div'>
            <div className='row main_div dark_div'>
                <div className='col-lg-6 col-md-6 col-sm-12 left_content'>
                    <div className='header_main'>
                        Where Culinary Magic Meets The Virtual Realm
                    </div>
                    <div className='content_main'>
                        Welcome to the future of cooking. Discover a world of virtual kitchens powered by cutting-edge technology, offering a myriad of culinary experiences in one place. Experience the convenience, variety, and innovation that our virtual multiple-kitchen brand brings to your fingertips.
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 image_main'>
                    <img src={contentimage} class="" alt="..." loading='lazy'/>
                </div>
            </div>
            <div className='row main_div light_div'>
                <div className='col-lg-6 col-md-6 col-sm-12 image_main'>
                    <img src={contentimage2} class="" alt="..." loading='lazy'/>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 left_content'>
                    <div className='header_main'>
                        Global Taste, Virtual Feast
                    </div>
                    <div className='content_main'>
                        Indulge your appetite with a virtual kitchens that satisfies every craving. From comforting classics to bold and adventurous flavors, our menu caters to your hunger, elevating your dining experience with every bite.
                    </div>
                </div>

            </div>
            <div className='row main_div dark_div'>
                <div className='col-lg-6 col-md-6 col-sm-12 left_content'>
                    <div className='header_main'>
                        Customer Bliss: Your Reviews Matter!
                    </div>
                    <div className='content_main'>
                        Experience customer bliss at Charmo Foods, where your satisfaction is our top priority. Your reviews matter to us, driving our commitment to delivering exceptional service. Join us and be part of our success story today!
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 image_main'>
                    <img src={contentimage3} class="" alt="..." loading='lazy'/>
                </div>
            </div>
            <div className='row main_div light_div' style={{marginBottom: '0px', paddingBottom: '40px'}}>
                <div className='col-lg-6 col-md-6 col-sm-12 image_main'>
                    <img src={contentimage4} class="" alt="..." loading='lazy'/>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 left_content'>
                    <div className='header_main'>
                        Trio's Culinary Passion, Charmo Foods Born: Taste the Dream!
                    </div>
                    <div className='content_main'>
                        Indulge in the delectable result of three Gen Z foodies' culinary passion. Charmo Foods is the manifestation of our dream to tantalize taste buds with innovative flavor fusions. Join us on this mouthwatering journey and experience the magic on your palate. Taste the dream!
                    </div>
                </div>

            </div>
        </div>
    )
}
