import { useState, useEffect } from "react";
import "./main.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import httpService from "./services/http.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faUtensilSpoon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./components/mainPage/header/header";
import Mainpage from "./components/mainPage/maipage";
import Brands from "./components/brands/brands";
import OurBrands from "./components/brands/ourBrands";
import Career from "./components/careers/career";
import Contact from "./components/contactus/contactus";


function App() {


  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Mainpage />}/>
          <Route path="/our-brands/:id" element={<OurBrands />}/>
          <Route path="/our-brands" element={<OurBrands />}/>
       
          <Route path="/careers" element={<Career/>}/>
          <Route path="/contact-us" element={<Contact/>}/>
        </Routes>
      </Router>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>

  );
}

export default App;
